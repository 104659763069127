import { MethodCode, ModelCode } from '../../@types/Permission';
import { findPermission } from '../../sections/@dashboard/Permissions/utils';
var level = function (user) {
    var hasPermissionCreateLevel = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.LEVEL, MethodCode.CREATE);
    var hasPermissionListLevel = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.LEVEL, MethodCode.LIST);
    var hasPermissionViewLevel = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.LEVEL, MethodCode.VIEW);
    var hasPermissionDeleteLevel = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.LEVEL, MethodCode.DELETE);
    var hasPermissionEditLevel = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.LEVEL, MethodCode.EDIT);
    return {
        hasPermissionEditLevel: hasPermissionEditLevel,
        hasPermissionCreateLevel: hasPermissionCreateLevel,
        hasPermissionListLevel: hasPermissionListLevel,
        hasPermissionViewLevel: hasPermissionViewLevel,
        hasPermissionDeleteLevel: hasPermissionDeleteLevel,
    };
};
export default level;
